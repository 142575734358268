<template>
    <div>
        <Navigation />
        <div class="bg_box">
            <div class="noneBox"></div>
            <div class="box">
                <p class="back" @click="$router.push('/aiNav')">返回</p>

                <div class="ct_box flex">
                    <div class="form_upload_box">
                        <ai_title ai_title="“一言一文”AI智能海报" />

                        <div class="form_ct flex">
                            <p class="upLoad_text">名称：</p>
                            <div class="upload_box">
                                <el-input v-model="form.poster_name" placeholder="请输入公司名称或品牌名称"></el-input>
                            </div>
                        </div>
                        <div class="form_ct flex">
                            <p class="upLoad_text">主题：</p>
                            <div class="upload_box">
                                <el-input v-model="form.poster_theme" placeholder="请输入海报主题"></el-input>
                            </div>
                        </div>
                        <div class="form_ct flex">
                            <p class="upLoad_text">内容描述：</p>
                            <div class="upload_box">
                                <el-input v-model="form.poster_content"
                                    placeholder="包含那些人物、动物、植物、事物以及背景是什么样子的简单描述"></el-input>
                            </div>
                        </div>
                        <div class="form_ct flex">
                            <p class="upLoad_text">风格阐述：</p>
                            <div class="upload_box">
                                <el-input v-model="form.poster_style" placeholder="如：科技、绿色环保、追求、稳定、形象、手绘等"></el-input>
                            </div>
                        </div>
                        <div class="form_ct flex">
                            <p class="upLoad_text">颜色构成：</p>
                            <div class="upload_box">
                                <el-input v-model="form.poster_color" placeholder="如：绿、天蓝、橙"></el-input>
                            </div>
                        </div>
                        <ai_select selectTitle="分辨率" :select_list="poster_resolution_list"
                            @getSelectVal="get_poster_resolution" />
                        <ai_button_file_data ref="ai_button_file_data" @file_data="file_data" />
                        <ai_img :type="1" :contentData="contentData" />
                    </div>
                    <aiNav />
                    <aiNum title="计费规则：1000tokens = 0.9元，" text="1000tokens在700-750字左右" :visible="aiNumShow"
                        @close="aiNumShow = false" @postData="postData" />
                    <aiPoup :visible="aiPoupShow" @close="aiPoupShow = false" />
                </div>
            </div>
        </div>
        <footerBot />
    </div>
</template>

<script>
import aiNav from '@/components/aiNav.vue'
import ai_select from '@/components/ai_select'
import ai_title from '@/components/ai_title'
import ai_img from '@/components/ai_img'
import ai_button_file_data from '@/components/ai_button_file_data'
export default {
    mounted() { this.getUserInfo() },
    components: {
        ai_title,
        aiNav,
        ai_select,
        ai_img,
        ai_button_file_data
    },
    data() {
        return {
            form: {
                poster_name: '', //名称
                poster_theme: '', //主题
                poster_content: '', //内容描述
                poster_style: '', //风格描述
                poster_color: '', //颜色构成
                poster_resolution: '', //分辨率
            },
            poster_resolution_list: ['1024x1024', '768x768', '768x1024', '1024x768', '576x1024', '1024x576'],
            contentData: {}, //内容
            aiNumShow: false,
            aiPoupShow: false,

        }
    },
    methods: {
        get_poster_resolution(val) {
            console.log(val);
            this.form.poster_resolution = val
        },
        get_type(val) {
            this.form.ppt_type = val
        },
        // 获取用户信息
        getUserInfo() {
            let $user_info = JSON.parse(localStorage.getItem('user_info'))
            if ($user_info) {
                this.curlGet('/api/user/info').then((res) => {
                    if (res.data.code) {
                        this.$user_info = res.data.data
                        this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
                    }
                })
            }
        },
        file_data() {
            // if (this.form.type == '') {
            //     return this.$message({
            //         message: '请输入从事行业',
            //         type: 'warning',
            //         offset: 80,
            //     });
            // }
            // if (this.form.business == '') {
            //     return this.$message({
            //         message: '请输入标题',
            //         type: 'warning',
            //         offset: 80,
            //     });
            // }
            // if (this.form.keyword == '') {
            //     return this.$message({
            //         message: '请输入关键字',
            //         type: 'warning',
            //         offset: 80,
            //     });
            // }
            if (this.$user_info.money <= 0) {
                this.aiPoupShow = true
            } else {
                this.aiNumShow = true
            }

        },
        postData() {
            this.aiNumShow = false
            this.$refs.ai_button_file_data.loading_show()
            this.curlPost('/file/create_poster', {
                poster_name: this.form.poster_name,
                poster_theme: this.form.poster_theme,
                poster_content: this.form.poster_content,
                poster_style: this.form.poster_style,
                poster_color: this.form.poster_color,
                poster_resolution: this.form.poster_resolution,
                mobile: this.$user_info.mobile,
            }).then(res => {
                this.$refs.ai_button_file_data.loading_show()
                if (res.data.code) {
                    this.contentData = res.data.data
                    // 扣费
                    this.curlPost('/api/users/money_log/spend', {
                        ai_id: res.data.data.id,
                        amount: res.data.data.money,
                    }).then(res => {
                        if (res.data.code) {
                            this.getUserInfo()

                        }
                    })
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        }
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai'
</style>